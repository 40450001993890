// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GEobS{list-style:none;margin:0;padding:0;bottom:0;position:fixed;margin:1em;max-width:300px;transition:height .5s;z-index:1}.QrSOX{display:inline-block}.QrSOX:not(:first-child){margin-top:1em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notifications": "GEobS",
	"notificationContainer": "QrSOX"
};
module.exports = ___CSS_LOADER_EXPORT___;
