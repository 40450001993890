// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aY2Ta{background:#fff;border:3px solid #000;box-shadow:0 0 20px rgba(0,0,0,.25);padding:.5em}.WEI3Y{background:none;border:0;padding:0;text-align:left;float:right;font-size:1.25em;line-height:.5;margin:-5px -5px 0 0;outline:none;padding:5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "aY2Ta",
	"close": "WEI3Y"
};
module.exports = ___CSS_LOADER_EXPORT___;
