// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iM86o{background:none;border:0;padding:0;text-align:left;cursor:pointer;font-weight:bold}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickable": "iM86o"
};
module.exports = ___CSS_LOADER_EXPORT___;
