import PropTypes from "prop-types";
import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import s from "../NotificationContent.scss";
import { NotificationContentProps } from "..";

const PostedTagToRestaurant = ({
  loggedIn,
  user,
  restaurantName,
  tagName,
  showMapAndInfoWindow,
}: NotificationContentProps) => {
  const restaurantEl = (
    <button
      className={s.clickable}
      onClick={showMapAndInfoWindow}
      type="button"
    >
      {restaurantName}
    </button>
  );
  if (loggedIn) {
    return (
      <span>
        <b>{user}</b> added tag{" "}
        <b>
          &ldquo;
          {tagName}
          &rdquo;
        </b>{" "}
        to {restaurantEl}.
      </span>
    );
  }
  return (
    <span>
      Tag
      <b>
        &ldquo;
        {tagName}
        &rdquo;
      </b>{" "}
      was added to
      {restaurantEl}.
    </span>
  );
};

PostedTagToRestaurant.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  user: PropTypes.string,
  restaurantName: PropTypes.string.isRequired,
  tagName: PropTypes.string.isRequired,
  showMapAndInfoWindow: PropTypes.func.isRequired,
};

PostedTagToRestaurant.defaultProps = {
  user: "",
};

export default withStyles(s)(PostedTagToRestaurant);
