// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./background.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./hamburger.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./hamburger-hover.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes _cOfs{from{transform:translate3d(0, 0, 0)}to{transform:translate3d(300px, -300px, 0)}}@keyframes qVmne{0%{transform:scaleX(1)}50%{transform:scaleX(1.5)}100%{transform:scaleX(1)}}.CJj5l{border-bottom:3px solid #000;position:relative}.RZanN{overflow:hidden;position:absolute;height:100%;width:100%}.yjYr_{animation:_cOfs infinite 15s linear;background:pink url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:600px;left:-300px;position:absolute;width:200%;z-index:0}._CFuR{margin:0 auto;padding:10px 0;position:relative;max-width:1000px}.ryjhm{text-align:center}.Fv33N{animation:qVmne .25s cubic-bezier(0.45, -0.61, 0.57, 1.48);background-size:contain;font-size:inherit;margin:0}.Fv33N a{outline:none}.Fv33N a:focus{filter:drop-shadow(0 0 3px rgba(0, 0, 0, 0.5))}.Fv33N img{height:6em}.oN2cw{position:fixed;width:100%;z-index:1051}.uMFRZ{background:none;border:0;padding:0;text-align:left;cursor:pointer;margin-top:-15px;outline:none;position:absolute;right:10px;top:50%}.uMFRZ span{text-indent:102%;white-space:nowrap;overflow:hidden;padding:0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat center;display:block;height:30px;transform:scale(1);transition:transform .1s;width:45px}.uMFRZ:hover span{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.uMFRZ:active span{transform:scale(1.25, 0.75)}.uMFRZ:focus{filter:drop-shadow(0 0 3px rgba(0, 0, 0, 0.5))}.J_Uoj .Fv33N img{height:5em}.IXKsk{background:none;border:0;padding:0;text-align:left;background:rgba(0,0,0,.5);height:100%;left:0;position:fixed;top:0;width:100%;z-index:2}@media only screen and (height <= 500px){.Fv33N img{height:5em}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CJj5l",
	"backgroundOverflow": "RZanN",
	"background": "yjYr_",
	"animatedBackground": "_cOfs",
	"container": "_CFuR",
	"banner": "ryjhm",
	"bannerTitle": "Fv33N",
	"squashStretch": "qVmne",
	"flashes": "oN2cw",
	"hamburger": "uMFRZ",
	"loggedIn": "J_Uoj",
	"menuBackground": "IXKsk"
};
module.exports = ___CSS_LOADER_EXPORT___;
