// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./footer-bg.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Vyu31{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;border-top:3px solid #000;padding:20px 15px}.Vyu31,.Vyu31 button{color:#fff;text-shadow:0 0 5px #000,0 0 10px #000,0 0 20px #000}.Eyan0{margin:0 auto;max-width:1000px;text-align:center}.bvzTn{border-left:1px solid #fff}.JDyy2,._aRRy{padding:2px 5px;font-size:1em}._aRRy,._aRRy:active,._aRRy:visited{background:none;border:0;color:#fff;outline:none;text-decoration:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Vyu31",
	"container": "Eyan0",
	"spacer": "bvzTn",
	"text": "JDyy2",
	"link": "_aRRy"
};
module.exports = ___CSS_LOADER_EXPORT___;
