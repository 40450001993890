// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fbKb0{padding:.5em}.lBXP5,.aA2gc{color:#fff}.lBXP5{background:red}.Bydzr{background:#ff0}.aA2gc{background:green}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "fbKb0",
	"error": "lBXP5",
	"success": "aA2gc",
	"notice": "Bydzr"
};
module.exports = ___CSS_LOADER_EXPORT___;
