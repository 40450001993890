// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LaGQ0{z-index:1;position:relative;background:#fff;padding:2em 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "LaGQ0"
};
module.exports = ___CSS_LOADER_EXPORT___;
